import React from 'react';
import Footer from './Footer';
import './Collection.css';

const Collection = () => {
    return (
        <div className='collection-body-div'>
            <div className='collection-body-div1'>
                <h1>Collection</h1>
            </div>
            <div className="carousel-container">
                <div className='carousel-container-div1'>

                </div>
                <div className="carousel-container-div2">
                    <h1>Gold</h1>
                    <p>Gold is pure and versatile,<br />
                        qualities that translate into<br />
                        our collection – distinctly<br />
                        Indian and yet very
                        contemporary.</p>
                        <button>View More</button>
                </div>
            </div>
            <div className="carousel-container2">
                <div className='carousel-container2-div1'>
                </div>
                <div className="carousel-container2-div2">
                    <h1>Diamond</h1>
                    <p>Diamonds are what dreams <br />
                    are made of and you can <br />
                    discover your perfect piece – <br />
                    beautifully designed and <br />
                    meticulously handcrafted</p>
                        <button>View More</button>
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default Collection;