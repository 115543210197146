import React from 'react';
import './About.css';
import Footer from './Footer';

const About = () => {
    return (
        <>
            {/* First section with background */}
            <div className='first-gif-div1'>
                <img src='https://i.pinimg.com/originals/ff/9c/20/ff9c204f62b65141a988cde3c7b1484f.jpg' />
            </div>

            {/* Why Choose Us section */}
            <div className="container-div">
                <h1>Why Choose Us?</h1>
                <div className="features">
                    {/* High Quality Diamonds */}
                    <div className="feature">
                        <div className="icon">💎</div>
                        <h3>High Quality Diamonds</h3>
                        <p>
                            We believe in quality of the highest standards. SB & Sons Jewellers
                            uses the best quality natural diamonds, as you deserve the best.
                            It is our utmost priority to provide customers with quality
                            products & services.
                        </p>
                    </div>

                    {/* Budget Jewellery */}
                    <div className="feature">
                        <div className="icon">💰</div>
                        <h3>Budget Jewellery</h3>
                        <p>
                            We make sure to keep affordable jewellery in all categories to
                            fulfill the demands of our new-age millennial customers.
                        </p>
                    </div>

                    {/* High Finish Products */}
                    <div className="feature">
                        <div className="icon">🎀</div>
                        <h3>High Finish Products</h3>
                        <p>
                            At SB & Sons Jewellers, we have a dedicated team of quality
                            control. Each product goes through rigorous quality checks before
                            getting the thumbs up.
                        </p>
                    </div>

                    {/* Customized Designs */}
                    <div className="feature">
                        <div className="icon">🤝</div>
                        <h3>Customized Designs</h3>
                        <p>
                            Each customer is unique, and so is their style. We help people
                            visualize and enhance their individual style and identity with
                            custom designs.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
export default About;
