import React from 'react'
import './Home.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Footer from './Footer';
const Home = () => {
    return (
        <>
            <div className='row'>
                <div className='first-gif-div'>
                    {/* <h1>Arbuda Jewellers</h1> */}
                </div>
            </div>
            <div className='row anime-div-container'>
                <h1>SHOP BY CATEGORY</h1>
                <div className='col-3 anime-div1'>
                    <img className='img-hover-effect' src='https://cdn.pixabay.com/photo/2024/03/27/19/39/ai-generated-8659741_640.jpg' alt='img-hover-effect' />
                    {/* <button className='btn'><span>Click Here</span></button> */}
                </div>
                <div className='col-3 anime-div2'>
                    <img className='img-hover-effect2' src='https://t3.ftcdn.net/jpg/06/25/48/46/240_F_625484602_xdEjucZDlTCLg1oQr6VKk7aRdxr2sADx.jpg' alt='img-hover-effect' />
                    {/* <button className='btn'><span>Click Here</span></button> */}
                </div>
                <div className='col-3 anime-div3'>
                    <img className='img-hover-effect3' src='https://w0.peakpx.com/wallpaper/127/1/HD-wallpaper-wedding-rings-white-gold-luxury-jewelry-diamonds.jpg' alt='img-hover-effect' />
                    {/* <button className='btn'><span>Click Here</span></button> */}
                </div>
                <div className="col-12 diamond-div">
                    <img src='https://sbandsonsjewellers.com/wp-content/uploads/2023/08/diamond-1.gif' alt='' />
                    <h1>DIAMOND</h1>
                </div>
                <div className="col-12 diamond-div-product">
                    <div className='col-12 diamond-products-container'>
                        <div className='col-0 product-div1'>
                            <img className='col-0 product-img-hover-effect' src='https://images.unsplash.com/photo-1693212793204-bcea856c75fe?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTF8fGRpYW1vbmQlMjBlYXJyaW5nc3xlbnwwfHwwfHx8MA%3D%3D' alt='product-img-hover-effect' />
                            <h4>Earings</h4>
                        </div>
                        <div className='col-0 product-div2'>
                            <img className='col-0 product-img-hover-effect' src='https://img.freepik.com/premium-photo/diamond-bangle-with-black-background_1057389-581.jpg' alt='product-img-hover-effect' />
                            <h4>Bangles</h4>
                        </div>
                        <div className='col-0 product-div3'>
                            <img className='col-0 product-img-hover-effect' src='https://c4.wallpaperflare.com/wallpaper/483/469/259/the-dark-background-shine-ring-diamond-wallpaper-preview.jpg' alt='product-img-hover-effect' />
                            <h4>Ring</h4>
                        </div>
                        <div className='col-0 product-div4'>
                            <img className='col-0 product-img-hover-effect' src='https://img.freepik.com/premium-photo/elegant-diamond-necklace_1283887-13670.jpg' alt='product-img-hover-effect' />
                            <h4>Necklace</h4>
                        </div>
                        <div className='col-0 product-div5'>
                            <img className='col-0 product-img-hover-effect' src='https://img.freepik.com/premium-photo/enchanting-beauty-4k-gold-necklace-with-realistic-symbol-captivating-white-backgroun_983420-13243.jpg' alt='product-img-hover-effect' />
                            <h4>Pendant</h4>
                        </div>
                    </div>
                </div>
                <div className='col-12 text-image-div-container'>
                    <div className='text-image-div1'>
                        <div className='text-div-for-text'>
                            <h2>Create
                                Memories
                                That Sparkle</h2>
                            <p>Make Your Every
                                Moment Memorable
                                With Style…..</p>
                            <p>Shine With
                                Diamonds And Gold
                                !!!</p>
                        </div>
                    </div>
                    <div className='text-image-div2'>
                        <div className='image1-of-div2'>
                            <img src='https://mtjjewellery.com/cdn/shop/files/7687tg_2.gif?v=1711653251&width=3840' />
                        </div>
                        <div className='image2-of-div2'>
                            <img src='https://mtjjewellery.com/cdn/shop/files/7687tg_2.gif?v=1711653251&width=3840' />
                        </div>
                    </div>
                </div>
                <div className='gold-image-div-container'>
                    <div className='gold-image-div1'>
                        <img src='https://sbandsonsjewellers.com/wp-content/uploads/2023/08/200w.gif' />
                        <h1>GOLD</h1>
                    </div>
                </div>
                <div className="col-12 diamond-div-product2">
                    <div className='col-12 diamond-products-container2'>
                        <div className='col-0 product1-div1'>
                            <img className='col-0 product1-img-hover-effect' src='https://png.pngtree.com/background/20230426/original/pngtree-two-beautiful-gold-wedding-rings-on-the-black-background-picture-image_2486967.jpg' alt='product-img-hover-effect' />
                            <h4>Rings</h4>
                        </div>
                        <div className='col-0 product2-div2'>
                            <img className='col-0 product-img-hover-effect' src='https://media.istockphoto.com/id/1203020545/photo/indian-bridal-ethnic-jewellery-gold.jpg?s=612x612&w=0&k=20&c=mBV4Ij1FaiJkx82UwrMPkkjcOvHzucP5kRG9bwAVhik=' alt='product-img-hover-effect' />
                            <h4>Necklace</h4>
                        </div>
                        <div className='col-0 product3-div3'>
                            <img className='col-0 product-img-hover-effect' src='https://c1.wallpaperflare.com/preview/494/65/156/jewellery-golden-gold-jewelry.jpg' alt='product-img-hover-effect' />
                            <h4>Bangles</h4>
                        </div>
                        <div className='col-0 product4-div4'>
                            <img className='col-0 product-img-hover-effect' src='https://img.freepik.com/free-photo/shiny-gold-jewelry-symbol-wealth-generated-by-ai_188544-10733.jpg' alt='product-img-hover-effect' />
                            <h4>Pandant</h4>
                        </div>
                        <div className='col-0 product5-div5'>
                            <img className='col-0 product-img-hover-effect' src='https://media.istockphoto.com/id/1133525210/photo/pair-of-fancy-golden-designer-earrings-closeup-macro-image-on-red-background.jpg?s=612x612&w=0&k=20&c=ZUknXtyeMhsS9Tdd2nRt8DkOTHc-6tmeG-KdTV_ODLU=' alt='product-img-hover-effect' />
                            <h4>Earings</h4>
                        </div>
                    </div>
                </div>
                <div className='col-12 prod-image-div-container'>
                    <div className='prod-image-div1'>
                        <img src='https://t4.ftcdn.net/jpg/06/95/46/17/360_F_695461716_tnpwMR7YYXFNFVI5rqqI0ldpVUM0rsKD.jpg' alt='image not found' />
                    </div>
                    <div className='prod-div-for-text'>
                        <h1>Find Your Wedding Look...</h1>
                        <div className="div-product2">
                            <div className='prod-container2'>
                                <div className='prod-div'>
                                    <img className='col-0 product1-img-hover-effect' src='https://sbandsonsjewellers.com/wp-content/uploads/2023/08/RVM-Jewels-Fashion-Latest-Multilayer-Chains-Stylish-Golden-Western-Neckpiece-Nec-4-630x552-1.jpg' alt='product-img-hover-effect' />
                                    <h3>Western</h3>
                                </div>
                                <div className='prod-div1'>
                                    <img className='col-0 product-img-hover-effect' src='https://sbandsonsjewellers.com/wp-content/uploads/2023/08/BannerMOB.jpg' alt='product-img-hover-effect' />
                                    <h3>Engagement</h3>
                                </div>
                                <div className='prod-div'>
                                    <img className='col-0 product-img-hover-effect' src='https://sbandsonsjewellers.com/wp-content/uploads/2023/08/6-806x644-1.webp' alt='product-img-hover-effect' />
                                    <h3>Traditional</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}
export default Home;
