import React from 'react';
import { FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa'; // Import icons
import './Contact.css';
import Footer from './Footer';


const Contact = () => {
    return (
        <>
            <div className="c-img-div-container">
                <div className="c-img-div">
                    <img
                        src="https://static.vecteezy.com/system/resources/previews/001/263/984/non_2x/contact-us-concept-free-photo.jpg"
                        alt="Contact"
                    />
                </div>
            </div>
            <div className="contact-container">
                <div className="contact-info">
                    <h2>You can find us at</h2>
                    <p><strong>Email:<br/>
                    </strong> hello@tyler.com</p><br/>
                    <p><strong>Phone Number:<br/>
                    </strong> +91 9982917070,<br/>+91  7357531996</p><br/>
                    <p>
                        <strong>Location:</strong> <br/>Haridev joshi circle, in front of Gupta sweets, <br/>Jalor
                    </p><br/>
                    <div className="social-icons">
                        <a href="#" className="icon"><FaTwitter size={24} /></a>
                        <a href="#" className="icon"><FaInstagram size={24} /></a>
                        <a href="#" className="icon"><FaYoutube size={24} /></a>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
export default Contact;
