import React from "react";
import "./Footer.css";

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-logo">
                    <p>Arbuda Jewellers</p>
                </div>
                <div className="footer-copyright">
                    <p>Copyright © 2020 Arbuda Jewellers Powered By Vardhman Softech</p>
                </div>
                <div className="footer-icons">
                    <a href="#" aria-label="Facebook">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/1/1b/Facebook_icon.svg"
                            alt="Facebook"
                        />
                    </a>
                    <a href="#" aria-label="Instagram">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
                            alt="Instagram"
                        />
                    </a>
                    <a href="#" aria-label="Google">
                        <img
                            src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png"
                            alt="Google"
                            onerror="this.src='https://via.placeholder.com/100';"
                        />
                    </a>

                    <a href="#" aria-label="WhatsApp">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                            alt="WhatsApp"
                        />
                    </a>
                    <a href="#" aria-label="Phone">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/6/6c/Phone_icon.png"
                            alt="Phone"
                        />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
